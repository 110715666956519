import React from "react"
import { Helmet } from "react-helmet"
import { PageProps } from "gatsby"
import { RichText } from "prismic-reactjs"
import { PrismicLink } from "./prismicLink"
import { GatsbyTransitionLinkProps, PageContext } from "../types"
import { GatsbyImage } from "gatsby-plugin-image"
import { useTransitionParameters } from "../hooks/useTransitionParameters"
import { Next } from "./next"
import { resizeImage } from "../utils/resizeImage"

function isProjectTextSlice(
  slice?: GatsbyTypes.Maybe<GatsbyTypes.PrismicProjectDataBodySlicesType>
): slice is GatsbyTypes.PrismicProjectDataBodyText {
  return slice?.slice_type === `text`
}

const Project = ({
  data: { prismicProject },
  pageContext,
  entry,
  exit,
  transitionStatus,
}: PageProps<{ prismicProject: GatsbyTypes.PrismicProject }, PageContext> & GatsbyTransitionLinkProps ): JSX.Element | null => {

  if (!prismicProject?.data) return null

  const data = prismicProject.data,
    featuredImageRatio = prismicProject.data.image?.fluid?.aspectRatio,
    headerWidth = 156 * (featuredImageRatio ?? 1),
    {
      headerTransitionClasses,
      headerTransitionStyles,
      mainTransitionClasses,
      mainTransitionStyles,
    } = useTransitionParameters({
      entry: entry,
      exit: exit,
      transitionStatus: transitionStatus,
    })

  const header = (
    <div>
      <div className="h-84 w-84 flex flex-col">
        <p className="w-44 text-lg">
          {typeof pageContext?.index === `number`
            && pageContext?.index >= 0
              ? `${pageContext.index + 1}`.padStart(3, `0`)
              : `XXX`
          }
        </p>
        {data.image?.gatsbyImageData ? (
          <GatsbyImage
            alt={data.image?.alt ?? ``}
            className="flex-auto w-auto mt-3"
            imgStyle={{
              objectFit: `contain`,
              objectPosition: `left center`,
            }}
            image={data.image?.gatsbyImageData}
          />
        ) : null}
        <p className="w-44 mt-3 text-lg uppercase">
          <h1 className="inline font-bold">{data.title}</h1>
          {data.year && `, ${data.year}`}
        </p>
      </div>
      {data.materials?.text !== `` || data.dimensions?.text !== `` ? (
        <p className="w-84 mt-3">
          <span className="block font-bold">Details</span>
          {data.materials?.text !== `` && data.materials?.raw && (
            <div className="w-84">
              <RichText
                render={data.materials.raw}
                serializeHyperlink={PrismicLink}
              />
            </div>
          )}
          {data.dimensions?.text !== `` && data.dimensions?.raw && (
            <div className="w-84">
              <RichText
                render={data.dimensions.raw}
                serializeHyperlink={PrismicLink}
              />
            </div>
          )}
        </p>
      ) : null}
      {data.exhibitions?.text !== `` && data.exhibitions?.raw && (
        <p className="w-84 mt-3">
          <span className="block font-bold">Exhibitions</span>
          <div className="w-84">
            <RichText
              render={data.exhibitions.raw}
              serializeHyperlink={PrismicLink}
            />
          </div>
        </p>
      )}
    </div>
  )

  const firstTextSlice = data.body?.find(slice => isProjectTextSlice(slice)) as GatsbyTypes.PrismicProjectDataBodyText,
    firstTextSliceContent = firstTextSlice?.primary?.text?.text,
    materialsDimensionsExhibitions = `${data.materials?.text && data.materials.text !== `` ? `${data.materials.text}. ` : ``}${data.dimensions?.text && data.dimensions.text !== `` ? `${data.dimensions.text}. ` : ``}${data.exhibitions?.text && data.exhibitions.text !== `` ? `${data.exhibitions.text}.` : ``}`,
    description = firstTextSliceContent ?? materialsDimensionsExhibitions ?? ``,
    resizedFeaturedImage = resizeImage(data.image?.gatsbyImageData)

  return (
    <div className="project">
      <Helmet>
        <title>{data.title}{data.year ? `, ${data.year}` : ``}</title>
        <meta name="twitter:title" content={`${data.title}${data.year && `, ${data.year}`}`} />
        <meta property="og:title" content={`${data.title}${data.year && `, ${data.year}`}`} />
        <meta name="description" content={description} />
        <meta name="twitter:description" content={description} />
        <meta property="og:description" content={description} />
        <meta name="twitter:image" content={resizedFeaturedImage.src} />
        <meta property="og:image" content={resizedFeaturedImage.src} />
        <meta property="og:image:width" content={`${resizedFeaturedImage.width}`} />
        <meta property="og:image:height" content={`${resizedFeaturedImage.height}`} />
      </Helmet>
      <header
        className={`hidden lg:block lg:fixed lg:z-10 lg:top-0 lg:mt-48 lg:ml-7 ${headerTransitionClasses}`}
        style={headerTransitionStyles}
      >
        {header}
      </header>
      <div className={mainTransitionClasses} style={mainTransitionStyles}>
        <header className="block lg:hidden w-col -max-w-12 mx-auto mt-32 mb-16">
          {header}
        </header>
        <main className="w-col -max-w-12 lg:mt-48 mx-auto">
          {data.body?.map((slice) => {
            let primary,
              embed
            switch (slice?.slice_type) {
              case `text`:
                primary = (slice as GatsbyTypes.PrismicProjectDataBodyText)?.primary
                return primary?.text?.raw ? (
                    <div
                      className={`mb-16 lg:mb-24 ${
                        primary?.size === `Large` ? `text-lg` : ``
                      } bg-white`}
                    >
                      <RichText
                        render={primary.text.raw}
                        serializeHyperlink={PrismicLink}
                      />
                  </div>
                ) : null
              case `image`:
                primary = (slice as GatsbyTypes.PrismicProjectDataBodyImage)?.primary
                return primary?.image?.gatsbyImageData ? (
                  <div className="mb-24">
                    {primary?.image?.gatsbyImageData && (
                      <GatsbyImage
                        className="w-full"
                        alt={primary.image?.alt ?? ``}
                        image={primary.image.gatsbyImageData}
                        sizes="(max-width: 672px) calc(100vw - 72px), 600px"
                      />
                    )}
                    {primary?.caption?.raw && (
                      <div className="mt-3">
                        <RichText
                          render={primary.caption.raw}
                          serializeHyperlink={PrismicLink}
                        />
                      </div>
                    )}
                  </div>
                ) : null
              case `video`:
                primary = (slice as GatsbyTypes.PrismicProjectDataBodyVideo)?.primary,
                embed = primary?.embed as any
                return embed ? (
                  <div className="mb-24">
                    {embed?.html && (
                      <div
                        className="embed relative w-full max-w-full h-0 overflow-hidden"
                        dangerouslySetInnerHTML={{ __html: embed.html }}
                        style={{
                          paddingBottom: `${embed?.height / embed?.width * 100}%`,
                        }}
                      />
                    )}
                    {primary?.caption?.raw && (
                      <div className="mt-3">
                        <RichText
                          render={primary.caption.raw}
                          serializeHyperlink={PrismicLink}
                        />
                      </div>
                    )}
                  </div>
                ) : null
              default:
                return null
            }
          })}
        </main>
        <Next index={pageContext.index} />
      </div>
    </div>
  )
}

export default Project
export { Project }
