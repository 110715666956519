import React from "react"
import TransitionLink from "gatsby-plugin-transition-link"
import { useTransitionParameters } from "../hooks/useTransitionParameters"
import { usePrismicMetadata } from "../hooks/usePrismicMetadata"

const Next = ({ index }: { index?: number }): JSX.Element | null => {
  const { data: { prismicMetadata } } = usePrismicMetadata()

  const indexContents = prismicMetadata?.data?.homepage?.document?.data?.body
      ?.filter(
        (slice) => slice?.slice_type === `project`
      )
      ?.map((slice, index: number) => {
        if (!slice?.primary?.project?.document)
          return {
            uid: null,
            index: index,
            title: null,
          }

        const project = slice?.primary?.project?.document
        return {
          uid: project?.uid,
          index: `${index + 1}`.padStart(3, `0`),
          title: project?.data?.title,
        }
      }) ?? [],
    currentIndex = index ?? -1,
    nextProject =
      indexContents[
        indexContents.length > currentIndex + 1 ? currentIndex + 1 : 0
      ],
    { transitionLinkProps } = useTransitionParameters({ transitionStatus: `` })

  return nextProject ? (
    <div className="mx-auto text-center">
      {nextProject.index}{` `}
      <TransitionLink
        className="link-uppercase"
        to={`/projects/${nextProject.uid}`}
        {...transitionLinkProps}
        style={{ textDecoration: `none` }}
      >
        {nextProject.title}
      </TransitionLink>
    </div>
  ) : null
}

export default Next
export { Next }