import React from "react"
import { graphql, PageProps } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { linkResolver } from "../utils/linkResolver"
import { Project } from "../components/project"
import { GatsbyTransitionLinkProps, PageContext } from "../types"

const ProjectTemplate = (props: PageProps<{ prismicProject: GatsbyTypes.PrismicProject }, PageContext> & GatsbyTransitionLinkProps) => {
  return (
    <Project {...props} />
  )
}

const ProjectTemplateWithPreview = withPrismicPreview(ProjectTemplate, [{
  repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY ?? ``,
  linkResolver,
}])

export default ProjectTemplateWithPreview

export const ProjectQuery = graphql`
  query ProjectBySlug($uid: String!) {
    prismicProject(uid: { eq: $uid }) {
      ...PrismicProject
    }
  }
`

export const prismicProjectFragment = graphql`
  fragment PrismicProject on PrismicProject {
    _previewable
    data {
      title
      year
      dimensions {
        html
        raw
        text
      }
      materials {
        html
        raw
        text
      }
      exhibitions {
        html
        raw
        text
      }
      image {
        alt
        copyright
        dimensions {
          height
          width
        }
        gatsbyImageData
        url
      }
      body {
        ... on PrismicProjectDataBodyText {
          id
          slice_type
          primary {
            text {
              html
              raw
              text
            }
            size
          }
        }
        ... on PrismicProjectDataBodyImage {
          id
          slice_type
          primary {
            image {
              copyright
              dimensions {
                height
                width
              }
              gatsbyImageData(layout: FULL_WIDTH)
              url
            }
            caption {
              html
              raw
              text
            }
          }
        }
      }
    }
    id
    prismicId
    type
    uid
  }
`
